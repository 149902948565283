import { createAction } from "@reduxjs/toolkit";

export const SET_STRUCTURE_DRAWING_TOOL = "SET_STRUCTURE_DRAWING_TOOL";
export const SAVE_SAVED_STRUCTURE = "SAVE_SAVED_STRUCTURE";
export const UPDATE_SAVED_STRUCTURES = "UPDATE_SAVED_STRUCTURES";
export const CLEAR_SAVED_STRUCTURES = "CLEAR_SAVED_STRUCTURES";

export interface IStructureArgs {
    user_id: number;
    structure: ISavedStructure;
}

export interface IStructuresArgs {
    user_id: number;
    structures: ISavedStructure[];
}

export interface ISavedStructure {
    id?: number;
    smiles?: string;
    name?: string;
}

export const setStructureDrawingTool = createAction<string>(
    SET_STRUCTURE_DRAWING_TOOL
);

export const saveStructure = createAction<IStructureArgs>(SAVE_SAVED_STRUCTURE);

export const updateStructure = (args: IStructureArgs) => {
    return updateStructures({
        user_id: args.user_id,
        structures: [args.structure],
    });
};

export const updateStructures = createAction<IStructuresArgs>(
    UPDATE_SAVED_STRUCTURES
);

export const clearStructures = createAction(CLEAR_SAVED_STRUCTURES);
