import { ISavedStructure } from "../actions/saveStructure";
import { ISavedStructuresState } from "../reducers/persistent/savedStructures";

const cleanStructures = (state) => {
    const saved_structures: ISavedStructuresState = {
        ...state.persistent.saved_structures,
    };

    for (const key in saved_structures) {
        if (saved_structures.hasOwnProperty(key)) {
            if (Array.isArray(saved_structures[key])) {
                const arr: ISavedStructure[] = saved_structures[key];
                saved_structures[key] = arr.map((struct) => ({
                    ...struct,
                    image: undefined,
                }));
            }
        }
    }
    return saved_structures;
};

export const migration20250323 = (state) => {
    return {
        ...state,
        persistent: {
            ...state.persistent,
            saved_structures: cleanStructures(state),
        },
    };
};
