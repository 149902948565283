export enum IUserFeature {
    ALL_FEATURES = "ALL_FEATURES",
    Agent = "Agent",
    ChemicalSearch = "ChemicalSearch",
    ChemicalEstimation = "ChemicalEstimation",
    ChemicalSearchEstimationDispatch = "ChemicalSearchEstimationDispatch",
    ChemicalAdvancedSearch = "ChemicalAdvancedSearch",
    ChemPredictorPrediction = "ChemPredictorPrediction",
    ReactionAnalysis = "ReactionAnalysis",
    ApiAccess = "ApiAccess",
    Daikin = "Daikin",
    ShowSubproperties = "ShowSubproperties",
    StructureDrawingKetcher = "StructureDrawingKetcher",
    NMRium = "NMRium",
    ViridisChemAdmin = "ViridisChemAdmin",
}

export interface IUserState {
    id?: number;
    email?: string;
    company?: string;
    environment?: string;
    userName?: string;
    firstName?: string;
    lastName?: string;
    csrf_token?: string;
    socket_token?: string;
    queries?: number;
    license_id?: number;
    licenseDisplayName?: string;
    lastLoggedIn?: number;
    licenseEnd?: number;
    hasLicense?: boolean;
    hasUserAdmin?: boolean;
    hasBilling?: boolean;
    hasMyAccount?: boolean;
    hasApiKeys?: boolean;
    license?: string;
    features?: IUserFeature[];
    seats?: number;
    role?: string;
    hasPendingAwsActivation?: boolean;
    isLicensedForEstimation?: boolean;
}

export const userHasFeature = (
    user: IUserState,
    feature: IUserFeature
): boolean =>
    user.features.find(
        (f) => f === IUserFeature.ALL_FEATURES || f === feature
    ) !== undefined;
