import { createMigrate } from "redux-persist";

import { migration20250323 } from "./20250323_cleanStructures";

export const createMigrations = createMigrate(
    {
        0: migration20250323,
    },
    { debug: false }
);
